import React from "react";
import { Button, Colors } from "@sellout/ui";
import { ButtonTypes, ButtonStates } from "@sellout/ui/build/components/Button";
import CANCEL_TICKETS from "@sellout/models/.dist/graphql/mutations/cancelTickets.mutation"
import GET_ORDER from '@sellout/models/.dist/graphql/queries/order.query';
import { ButtonContainer } from "../../pages/EventOrders.page";
import { Container } from "./AddTicketBlockModal";
import * as AppActions from "../../redux/actions/app.actions";
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { BackstageState } from "../../redux/store";
import { useMutation } from "@apollo/react-hooks";



enum ConfirmModal {
  title = "Cancel ticket sharing",
  confirmText = "CANCEL",
  cancelText = "CONFIRM",
}
const CancelSharingTicketsModal = () => {
  /* Actions */
  const dispatch = useDispatch();
  const orderState = useSelector((state: BackstageState) => state.order);
  const { orderId } = orderState;
  const popModal = () => dispatch(AppActions.popModal());

  const ConfirmHeaderText = styled.span`
    font-weight: 600;
    font-size: 1.6rem;
    color: ${Colors.Grey1};
    display: flex;
    justify-content: center;
    padding: 0px 11px 12px 10px;
  `;
  const ConfirmText = styled.span`
    font-weight: 450;
    font-size: 1.4rem;
    color: ${Colors.Grey1};
        line-height: 23px;
  `;
  const [cancelTickets, { loading:cancelTicketLoading, error }] = useMutation(CANCEL_TICKETS, {

    onCompleted(data) {
      popModal()
    },
    refetchQueries: [
      {
        query: GET_ORDER,
        variables: { orderId },
      },
    ],
  });
  return (
    <>
        <ModalContainer width="430px">
          <>
            <ModalHeader title={ConfirmModal.title} close={popModal} />
            <ModalContent>
              <Container>
                {<ConfirmHeaderText>Are you sure?</ConfirmHeaderText>}{" "}
                <ConfirmText>
                All tickets in Offered status will be returned to your account. Are you sure you want to do this?
                </ConfirmText>{" "}
              </Container>
            </ModalContent>
            <ModalFooter>
              <div />
              <ButtonContainer>
                <Button
                  type={ButtonTypes.Thin}
                  text={ConfirmModal.confirmText}
                  state={ButtonStates.Warning}
                  margin="0px 10px 0px 0px"
                  onClick={() => popModal()}
                />
                <Button
                  type={ButtonTypes.Thin}
                  text={ConfirmModal.cancelText}
                  state={ButtonStates.Active}
                  margin="0px 10px 0px 0px"
                    onClick={() => {
                      cancelTickets({
                        variables:{
                        orderId,
                        }
                        });
                        }}
                />
              </ButtonContainer>
            </ModalFooter>
          </>
        </ModalContainer>
    </>
  );
};

export default CancelSharingTicketsModal;
