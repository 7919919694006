import React from "react";
import styled from "styled-components";
import Icon, { Icons } from "@sellout/ui/build/components/Icon";
import { Colors } from "@sellout/ui/build/Colors";
import { ShareTicketSteps } from "./MyTicketShareScreenSteps";


const Container = styled.div`
  position: relative;
  height: 24px;
  width: 24px;
  background-color: ${Colors.Grey4};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  transition: 0.2s;

  &:hover {
    background-color: ${Colors.Grey3};
  }
`;

type BackButtonProps = {
    stepState?:any,
    setStepState?:any
};

const BackButton: React.FC<BackButtonProps> = ({stepState,setStepState}) => {
  /** Actions **/


  const handleBack = () => {
    switch (stepState) {
      case ShareTicketSteps.ContactInfo:
        setStepState(ShareTicketSteps.SelectTicket);
        break;
      case ShareTicketSteps.SharingConfirmation:
        setStepState(ShareTicketSteps.ContactInfo);
        break;
      case ShareTicketSteps.SharingComplete:
        setStepState(ShareTicketSteps.SharingConfirmation);
        break;
      default:
        break;
    }
  };
  
  /** Render **/
  return (
    <Container>
      <Icon
        icon={Icons.BackArrow}
        color={Colors.White}
        onClick={handleBack}
        size={16}
        top="1px"
      />
    </Container>
  );
};

export default BackButton;
