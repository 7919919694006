import React from "react";
import styled from "styled-components";
import { Colors } from "@sellout/ui/build/Colors";
import BackButton from "./BackButtonMyTicket";
import { ShareTicketSteps } from "./MyTicketShareScreenSteps";

type LinePadding = {
  padding?: string;
};
type HeaderProps = {
  fontSize?: string;
};
const Container = styled.div`
  position: relative;
`;

export const HeaderBar = styled.div<LinePadding>`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.OffWhite};
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.padding ? props.padding : "24px 24px 8px")};
`;

export const HeaderBarText = styled.div<HeaderProps>`
  font-size:${(props) => (props.fontSize ? props.fontSize : "1.4rem")};
  line-height: 2.4rem;
  font-weight: 500;
  color: ${Colors.Grey1};
`;

const Blank = styled.div`
  height: 24px;
  display: flex;
  flex-direction: row;
  background-color: ${Colors.Grey1};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type ScreenHeaderProps = {
  title?: string;
  blank?: boolean;
  padding?: string;
  stepState?: any;
  setStepState?: any;
};

const ScreenHeaderMyTicket: React.FC<ScreenHeaderProps> = ({
  title,
  stepState,
  setStepState,
  blank = false,
  padding,
}) => {
  /** State **/

  if (blank) {
    return (
      <Container>
        <Blank />
      </Container>
    );
  }

  /** Render **/
  return (
    <Container>
      <HeaderBar padding={padding}>
        <Row>
          {stepState !== ShareTicketSteps.SharingComplete &&
            stepState !== ShareTicketSteps.SelectTicket && (
              <BackButton stepState={stepState} setStepState={setStepState} />
            )}

          <HeaderBarText>{title}</HeaderBarText>
        </Row>
      </HeaderBar>
    </Container>
  );
};

export default ScreenHeaderMyTicket;
