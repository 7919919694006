import React, { useEffect, useState } from "react";
import {
  ModalContainer,
  ModalFooter,
  ModalHeader,
} from "./modal/Modal";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GET_ORDER from "@sellout/models/.dist/graphql/queries/order.query";
import TICKET_SHARE from "@sellout/models/.dist/graphql/mutations/sharingTicket.mutation";
import { BackstageState } from "../redux/store";
import { useMutation, useQuery } from "@apollo/client";
import Error from '../elements/Error';
import {
  SectionHeader,
} from "./modal/RefundModal";
import {
  Button,
  Colors,

} from "@sellout/ui";
import { ButtonStates, ButtonTypes } from "@sellout/ui/build/components/Button";
import styled from "styled-components";
import * as OrderActions from "../redux/actions/order.actions";
import { ContactInfo } from "./SharingContactInfo";
import {
  EventDetailsRight,
  Section,
} from "./modal/UserOrderTicketModal";
import { media } from "@sellout/ui/build/utils/MediaQuery";
import { SelectTicketAddons } from "./SelectTicketAddons";
import ScreenHeaderMyTicket from "./ScreenHeaderMyTicket";
import { AppNotificationTypeEnum } from "../models/interfaces/IAppNotification";
import * as AppActions from "../redux/actions/app.actions";


const TextConfirmation = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
  color: ${Colors.Grey2};
  margin-bottom: 47px;
  text-align: center;
  line-height: 26px;
`;
const Content = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  justify-content: center;
`;


export const Text = styled.div`
  font-size: 1.6rem;
  color: ${Colors.Grey2};
  padding: 10px 20px;
  text-align: left;
  line-height: 24px;
`;
export const ScreenStep = styled.div`
  width: 450px;
  height: 480px;
  overflow: auto;
  ${media.mobile`
      width: 330px;
     `};
`;

export const ScreenFooter = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
`;
export const SharingDetails = styled.div`
  display: block;
  font-size: 16px;
`;
export const MainWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
  row-gap: 17px;
  padding: 20px 0;
`;

export enum ShareTicketSteps {
  SelectTicket = "selectTicket",
  ContactInfo = "contactInfo",
  SharingConfirmation = "sharingConfirmation",
  SharingComplete = "sharingComplete",
}

enum ConfirmModal {
  title = "Share your tickets",
  confirmText = "CONFIRM",
  next = "NEXT",
  cancelText = "BACK",
  done = "DONE",
}

const MyTicketShareScreenSteps = ({popModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const orderState = useSelector((state: BackstageState) => state.order);
  const { orderId,ordersCache } = orderState;
  const order = ordersCache[orderId];
  const [stepState, setStepState] = useState<ShareTicketSteps>(
    ShareTicketSteps.SelectTicket
  );
  const { selectedTickets, selectedAddons, email, errors,phoneNumber, userExists } = orderState;

const [errorMsg,setErrorMsg]=useState<string>("")
  const { data } = useQuery(GET_ORDER, {
    variables: {
      orderId,
    },
  });

  const [saveSharingDetails, { loading: savingLoading }] = useMutation(TICKET_SHARE, {
    onError(error) {
      setErrorMsg(error.message);
      dispatch(AppActions.showNotification(error.message, AppNotificationTypeEnum.Error));

    },
    onCompleted(data) {
      setStepState(ShareTicketSteps.SharingComplete)
    },
  });
  const selectedTicketNames = data?.order?.tickets
    .filter((ticket) => selectedTickets.includes(ticket._id))
    .map((ticket) => ({
        ticketName: ticket.name,
        seat: ticket.seat
    }));
  const selectedUpgradeName = data?.order?.upgrades
    .filter((upgrades) => selectedAddons.includes(upgrades._id))
    .map((upgradeName) => upgradeName.name);

  const navigateToTickets = () => {
    popModal();
    history.push(`/my-tickets`);
    dispatch(OrderActions.setTicket(""));
    dispatch(OrderActions.setAddons(""));
    popModal();
    dispatch(OrderActions.setSharingEmail(""));
    dispatch(OrderActions.SetPhoneNumber(""));

  };

  
  // Sharing Confirmation Screen

  const SharingConfirmation = ({stepState,setStepState}) => {
    return (
      <>
      <ScreenHeaderMyTicket title="Sharing confirmation" padding="18px 19px 14px" stepState={stepState} setStepState={setStepState} />
        <Text>
          Please confirm that the tickets / add-ons and the contact information
          below is correct. Once you click Confirm, we will send a message
          notifying your friend that tickets are waiting for them
        </Text>
        
        {selectedTicketNames?.length ? (
          <Section>
            <SectionHeader>
              <EventDetailsRight>Tickets</EventDetailsRight>
            </SectionHeader>
            <MainWrapper>
              <>
                {selectedTicketNames.map((item) => {
                  return <SharingDetails>{item?.ticketName} {item.seat ? ` (${item.seat}) ` : null} </SharingDetails>;
                })}
              </>
            </MainWrapper>
          </Section>
        ) : (
          ""
        )}
        {selectedUpgradeName?.length ? (
          <Section>
            <SectionHeader>
              <EventDetailsRight>Add-ons</EventDetailsRight>
            </SectionHeader>
            <MainWrapper>
              <>
                {selectedUpgradeName.map((item) => {
                  return <SharingDetails>{item}</SharingDetails>;
                })}
              </>
            </MainWrapper>
          </Section>
        ) : (
          ""
        )}
        {email?.length ?(
          <Section>
          <SectionHeader>
            <EventDetailsRight>Email</EventDetailsRight>
          </SectionHeader>
          <MainWrapper>
            <>
              {" "}
              <SharingDetails>{email}</SharingDetails>
            </>
          </MainWrapper>
        </Section>):""}
        {phoneNumber&& phoneNumber?.length > 1 ?(
          <Section>
          <SectionHeader>
            <EventDetailsRight>Phone number</EventDetailsRight>
          </SectionHeader>
          <MainWrapper>
            <>
              {" "}
              <SharingDetails>+{phoneNumber}</SharingDetails>
            </>
          </MainWrapper>
        </Section>):""}
      </>
    );
  };

  // SharingComplete Screen

  const SharingComplete = ({stepState,setStepState}) => {
    return (
      <><ScreenHeaderMyTicket title="Sharing complete" padding="18px 34px 14px" stepState={stepState} setStepState={stepState=setStepState} /><Content>
        <TextConfirmation>
          Your tickets have been shared. You can cancel the sharing transaction
          before your friend accepts them by clicking on your order again.
        </TextConfirmation>
      </Content></>
    );
  };

  const renderStep = () => {
    // All sharing screen are in steps to render

    switch (stepState) {
      case ShareTicketSteps.SelectTicket:
        return <SelectTicketAddons data ={data} stepState={stepState} setStepState={setStepState}/>;
      case ShareTicketSteps.ContactInfo:
        return <ContactInfo stepState={stepState} setStepState={setStepState} setErrorMsg={setErrorMsg}/>;
      case ShareTicketSteps.SharingConfirmation:
        return <SharingConfirmation stepState={stepState} setStepState={setStepState}/>;
      case ShareTicketSteps.SharingComplete:
        return <SharingComplete stepState={stepState} setStepState={setStepState}/>;
      default:
        return null;
    }
  };

  // The state of the Common Button on steps

  const Footer = () => {
    const handleNext = () => {
      switch (stepState) {
        case ShareTicketSteps.SelectTicket:
          return selectedTickets.length || selectedAddons.length;
        case ShareTicketSteps.ContactInfo:
          return (errors?.UserEmail ==""&& email?.length !==0) || userExists || errors?.UserPhoneNumber ==""
          // phoneNumber && phoneNumber.length > 7  
        case ShareTicketSteps.SharingConfirmation:
          return true;
        case ShareTicketSteps.SharingComplete:
          return true;
        default:
          return false;
      }
    };

    const activeNextState = handleNext();

    // Handling the click on footer Common buttons on Each steps

    const handleClickNext = () => {
      switch (stepState) {
        case ShareTicketSteps.SelectTicket:
          setStepState(ShareTicketSteps.ContactInfo);
          break;
        case ShareTicketSteps.ContactInfo:
          setStepState(ShareTicketSteps.SharingConfirmation);
          break;
        case ShareTicketSteps.SharingConfirmation:
          saveSharingDetails({
            variables: {
              params: {
              email,
              phoneNumber:email &&email?.length >1 ? "" : phoneNumber,
              tickets:selectedTickets,
              upgrades:selectedAddons,
              eventName:order?.eventName,
              orderId:orderId
              }
            },
          });
          break;
        case ShareTicketSteps.SharingComplete:
          navigateToTickets();
          break;
        default:
          break;
      }
    };
 
    return (
      <ModalFooter>
        <ScreenFooter>
          <div />
          <Button
            type={ButtonTypes.Next}
            text={
              stepState === ShareTicketSteps.SharingConfirmation
                ? ConfirmModal.confirmText
                : stepState === ShareTicketSteps.SharingComplete
                ? ConfirmModal.done
                : ConfirmModal.next
            }
            loading={savingLoading}
            state={
              activeNextState ? ButtonStates.Active : ButtonStates.Disabled
            }
            onClick={handleClickNext}
          />
        </ScreenFooter>
      </ModalFooter>
    );
  };

  return (
    <ModalContainer>
      <ModalHeader title={ConfirmModal.title} close={navigateToTickets} />
      <ScreenStep className="menu-head-wrapper">{renderStep()}</ScreenStep>
      <Footer />
    </ModalContainer>
  );
};

export default MyTicketShareScreenSteps;
