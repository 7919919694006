import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import * as AppActions from "../../redux/actions/app.actions";
import Button, {
  ButtonTypes,
  ButtonStates,
} from "@sellout/ui/build/components/Button";
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "./Modal";
import { BackstageState } from "../../redux/store";
import * as EventActions from "../../redux/actions/event.actions";
import useNavigateToDuplicateCreateEvent from "../../hooks/useNavigateToDuplicateCreateEvent.hook";
import { NEW_EVENT_ID } from "../../redux/reducers/event.reducer";
import { EventTypeEnum, IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import { eventSchedule, performanceState } from "../../models/states/event.state";
import * as DefaultImage from "../../utils/DefaultImage";
import shortid from "shortid";
import useEvent from "../../hooks/useEvent.hook";


const Container = styled.div`
  /* width: 375px; */
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const DuplicateEventModal: React.FC = () => {
  /* Hooks */
  const navigateToDuplicateCreateEvent = useNavigateToDuplicateCreateEvent();

  /* State */
  const {eventId,duplicateEventId, duplicateEventCreate} = useSelector((state: BackstageState) => state.event);
const[loading,setLoading] = useState(false)

  const { event } = useEvent(eventId,true);

  /* State */

  const [copyOrgionalEvent, setCopyOrgionalEvent] = useState(
    event as IEventGraphQL
  );
  
  /* Actions */
  const dispatch = useDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  let title = "Duplicate Event";
  let confirmText = "CANCEL";
  let cancelText = "CONFIRM";

  useEffect(() => {
    const updatedTicketTypes = event?.ticketTypes?.map(ticketItem => ({
      ...ticketItem,
      _id: shortid.generate(),
      remainingQty: ticketItem.totalQty,
      tiers: ticketItem.tiers.map(tier => ({
        ...tier,
        _id: shortid.generate(),
        remainingQty: ticketItem.totalQty
      }))
    })) ?? [];
  
    const updatedUpgrades = event?.upgrades?.map(upgrade => ({
      ...upgrade,
      _id: shortid.generate(),
      remainingQty: upgrade.totalQty,
      ticketTypeIds: updatedTicketTypes.map(ticketItem => ticketItem._id)
    })) ?? [];
  
    setCopyOrgionalEvent(prevData => ({
      ...prevData,
      name: "",
      subtitle: "",
      _id: "new",
      createdAt: 0,
      description: "",
      posterImageUrl: DefaultImage.getEventImage(EventTypeEnum.GeneralEvent),
      published: false,
      publishable: false,
      promotions: [],
      hasOrders: false,
      isMultipleDays: false,
      salesBeginImmediately: true,
      performances: [performanceState()],
      schedule: eventSchedule(),
      ticketTypes: updatedTicketTypes,
      upgrades: updatedUpgrades,
      stub:"",
      cancel:false,
    }));
  }, [event]);
  
  /* Handlers */
  const handleConfirm = (eventId) => {
    setLoading(true)
    dispatch(EventActions.setDuplicateEventId(eventId));
    dispatch(EventActions.setDuplicateEventOpen(true));
    dispatch(EventActions.setEventId(NEW_EVENT_ID))
    dispatch(EventActions.cacheEvents([copyOrgionalEvent]));
    dispatch(EventActions.createEventRequest());
    navigateToDuplicateCreateEvent(eventId);
  };


  useEffect(() => {
    if (duplicateEventCreate && !duplicateEventId ) {
      dispatch(AppActions.popModal())
      setLoading(false)
    }
  }, [event?.fees]);

  

  /* Render */
  return (
    <ModalContainer width="450px">
      <ModalHeader title={title} close={popModal} />
      <ModalContent>
        <Container>Are you sure want to duplicate this event?</Container>
      </ModalContent>
      <ModalFooter>
        <div />
        <ButtonContainer>
          <Button
            type={ButtonTypes.Thin}
            text={confirmText}
            state={ButtonStates.Warning}
            margin="0px 10px 0px 0px"
            onClick={() => popModal()}           
          />
          <Button
            type={ButtonTypes.Thin}
            text={cancelText}
            state={ButtonStates.Active}
            margin="0px 10px 0px 0px"
            loading={loading}
            onClick={() => handleConfirm(eventId)}
          />
        </ButtonContainer>
      </ModalFooter>
    </ModalContainer>
  );
};

export default DuplicateEventModal;
