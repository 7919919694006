import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import * as AppActions from "../../redux/actions/app.actions";
import { Button, Colors } from "@sellout/ui";
import { ButtonTypes, ButtonStates } from "@sellout/ui/build/components/Button";
import { ButtonContainer } from "../../pages/EventOrders.page";
import { Container } from "./AddTicketBlockModal";
import CreateOrganizationModal from "../account/CreateOrganizationModal";
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "./Modal";
import GET_PROFILE from "@sellout/models/.dist/graphql/queries/profile.query";

export const OrgName = styled.span`
  font-weight: 600;
  font-size: 1.4rem;
  color: ${Colors.Grey1};
`;
enum ConfirmModal {
  title = "Create a new organization",
  confirmText = "CANCEL",
  cancelText = "CONFIRM",
}
const CreateNewOrgModal = () => {
  /* Actions */
  const dispatch = useDispatch();
  const popModal = () => dispatch(AppActions.popModal());
  const { data } = useQuery(GET_PROFILE);
  const [onClickConfirm, setOnClickConfirm] = useState(false);

  const clickOrgModal = () => {
    setOnClickConfirm(true);
  };

  const navigateToDashboard = () => {
    setOnClickConfirm(false);
    popModal();
    window.location.href = "/admin/dashboard";
  };
  return (
    <ModalContainer width="430px">
      {!onClickConfirm && (
        <>
          <ModalHeader title={ConfirmModal.title} close={popModal} />
          <ModalContent>
            <Container>
              Are you sure? New organizations are only required when you need to
              connect to multiple Stripe accounts. You will have to recreate all
              venues and seating charts, and no reporting will be consolidated
              with the&nbsp;
              {<OrgName>{data?.organization?.orgName || ""}</OrgName>}
              &nbsp;organization. Do you really want to create a new
              organization?
            </Container>
          </ModalContent>
          <ModalFooter>
            <div />
            <ButtonContainer>
              <Button
                type={ButtonTypes.Thin}
                text={ConfirmModal.confirmText}
                state={ButtonStates.Warning}
                margin="0px 10px 0px 0px"
                onClick={() => popModal()}
              />
              <Button
                type={ButtonTypes.Thin}
                text={ConfirmModal.cancelText}
                state={ButtonStates.Active}
                margin="0px 10px 0px 0px"
                onClick={() => clickOrgModal()}
              />
            </ButtonContainer>
          </ModalFooter>
        </>
      )}
      {onClickConfirm && (
        <CreateOrganizationModal
          onClickConfirm={onClickConfirm}
          navigateToDashboard={navigateToDashboard}
          setOnClickConfirm={setOnClickConfirm}
        />
      )}
    </ModalContainer>
  );
};

export default CreateNewOrgModal;
