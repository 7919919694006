import React from "react";
import { useHistory } from "react-router-dom";
import {  useSelector } from "react-redux";
import { BackstageState } from "../redux/store";

type NavigateToDuplicateCreateEvent = (eventId?:string
) => void;

type NavigateToDuplicateCreateEventHook = () => NavigateToDuplicateCreateEvent;

const useNavigateToDuplicateCreateEvent: NavigateToDuplicateCreateEventHook =
  () => {
    /** Routing */
    const history = useHistory();

    /* Actions */
    const eventState = useSelector((state: BackstageState) => state.event);
  
    const { eventId, eventsCache } = eventState;
    const event = eventsCache[eventId];


    const createEvent = React.useCallback((eventId) => {
        history.push(`/create-event/details?eventId=${eventId}`);
      },[]
    );

    /** Return */
    return createEvent;
  };

export default useNavigateToDuplicateCreateEvent;
