import IOrder from '@sellout/models/.dist/interfaces/IOrder';
import { ErrorKeyEnum } from '../reducers/order.reducer';

export const OrderActionTypes = {
    // Order Id
    SET_ORDER_ID: "SET_ORDER_ID",
    // Cache Orders
    CACHE_ORDERS: 'CACHE_ORDERS',
    // Order Refunded
    ORDER_REFUNDED: "ORDER_REFUNDED",
    SET_BATCH_PRINT_ORDER_IDS: "SET_BATCH_PRINT_ORDER_IDS",
    // Ticket Share
    SET_TICKET_IDS: "SET_TICKET_IDS",
    SET_ADD_ONS_IDS: "SET_ADD_ONS_IDS",
    SET_SHARING_EMAIL: "SET_SHARING_EMAIL",
    SET_ERRORS: "SET_ERRORS",
    SET_PHONE_NUMBER: "SET_PHONE_NUMBER",
    SET_USER_EXISTS: "SET_USER_EXISTS",
    SET_USER_CONFIRM_TICKET: " SET_USER_CONFIRM_TICKET",
    SET_USER_SHARED_IDS: "SET_USER_SHARED_IDS",
    SET_USER_SHARED_LOGIN_EMAIL: "SET_USER_SHARED_LOGIN_EMAIL",
    SET_USER_SHARED_LOGIN_PHONE_NUMBER: "SET_USER_SHARED_LOGIN_PHONE_NUMBER",
    SET_USER_SHARED_ACCEPT_MODAL: "SET_USER_SHARED_ACCEPT_MODAL",
    SET_USER_NAVIGATION_ROUTE: "SET_USER_NAVIGATION_ROUTE",
    SET_USER_CONFIRM_ACCEPT_MODAL: "SET_USER_CONFIRM_ACCEPT_MODAL",


};

/********************************************************************************
 *  Venue Action Creators
 *******************************************************************************/
export type OrderActionCreatorTypes =
    // Order Id
    | SetOrderIdAction
    // Cache Orders
    | CacheOrdersAction
    // Set Order Refunded
    | setOrderRefundedAction
    | SetBatchPrintOrderIdsAction
    // Ticket Share
    | SetTicketIdsAction
    | SetAddonsIdsAction
    | SetSharingEmailAction
    | SetErrorsAction
    | SetPhoneNumberAction
    | SetUserExistsAction
    | SetUserConfirmTicketAction
    | SetUserSharedIdsAction
    | SetUserSharedLoginAction
    | SetUserSharedLoginPhoneNumberAction
    | SetUserSharedAcceptModalAction
    | SetUserNavigationRouteAction
    | SetUserConfirmAcceptModalAction




/********************************************************************************
 *  Set Order ID
 *******************************************************************************/

export interface SetOrderIdAction {
    type: typeof OrderActionTypes.SET_ORDER_ID;
    payload: {
        orderId: string;
    };
}

export function setOrderId(orderId: string): SetOrderIdAction {
    return {
        type: OrderActionTypes.SET_ORDER_ID,
        payload: {
            orderId
        }
    };
}
/********************************************************************************
 *  Set Ticket-IDS
 *******************************************************************************/

export interface SetTicketIdsAction {
    type: typeof OrderActionTypes.SET_TICKET_IDS;
    payload: {
        selectedTickets:string
    };
}

export function setTicket(selectedTickets: string): SetTicketIdsAction {
    return {
        type: OrderActionTypes.SET_TICKET_IDS,
        payload: {
            selectedTickets
        }
    };
}
/********************************************************************************
 *  Set Addons-IDS
 *******************************************************************************/

export interface SetAddonsIdsAction {
    type: typeof OrderActionTypes.SET_ADD_ONS_IDS;
    payload: {
        selectedAddons
    };
}

export function setAddons(selectedAddons: string): SetAddonsIdsAction {
    return {
        type: OrderActionTypes.SET_ADD_ONS_IDS,
        payload: {
            selectedAddons
        }
    };
}
/********************************************************************************
 *  Set Sharing Email
 *******************************************************************************/

export interface SetSharingEmailAction {
    type: typeof OrderActionTypes.SET_SHARING_EMAIL;
    payload: {
        email:string
    };
}

export function setSharingEmail(email: string): SetSharingEmailAction {
    return {
        type: OrderActionTypes.SET_SHARING_EMAIL,
        payload: {
            email
        }
    };
}
/********************************************************************************
 *  Set Errors Sharing Ticket
 *******************************************************************************/

export interface SetErrorsAction {
    type: typeof OrderActionTypes.SET_ERRORS;
    payload: {
        key:ErrorKeyEnum
        errors :string
    };
}

export function SetErrors(key: ErrorKeyEnum,errors: string): SetErrorsAction {
    return {
        type: OrderActionTypes.SET_ERRORS,
        payload: {
            key,
            errors
        }
    };
}
/********************************************************************************
 *  Set Phone-Number Sharing Ticket
 *******************************************************************************/

export interface SetPhoneNumberAction {
    type: typeof OrderActionTypes.SET_PHONE_NUMBER;
    payload: {
        phoneNumber:string 
    };
}

export function SetPhoneNumber(phoneNumber: string): SetPhoneNumberAction {
    return {
        type: OrderActionTypes.SET_PHONE_NUMBER,
        payload: {
            phoneNumber
        }
    };
}

/********************************************************************************
 *  Set Phone-Number User Exist
 *******************************************************************************/
export interface SetUserExistsAction {
    type: typeof OrderActionTypes.SET_USER_EXISTS;
    payload: {
        userExists:boolean
    };
}

export function SetUserExists(userExists: boolean): SetUserExistsAction {
    return {
        type: OrderActionTypes.SET_USER_EXISTS,
        payload: {
            userExists
        }
    };
}
/********************************************************************************
 *  Set User Confirm Ticket
 *******************************************************************************/
export interface SetUserConfirmTicketAction {
    type: typeof OrderActionTypes.SET_USER_CONFIRM_TICKET;
    payload: {
        confirmTicket:boolean
    };
}

export function SetUserConfirmTicket(confirmTicket: boolean): SetUserConfirmTicketAction {
    return {
        type: OrderActionTypes.SET_USER_CONFIRM_TICKET,
        payload: {
            confirmTicket
        }
    };
}
/********************************************************************************
 *  Set User Shared Ids
 *******************************************************************************/
export interface SetUserSharedIdsAction {
    type: typeof OrderActionTypes.SET_USER_SHARED_IDS;
    payload: {
        ordersId:string, sharingId:string, indicator:boolean
    };
}

export function SetUserSharedIds(ordersId:string, sharingId:string, indicator:boolean): SetUserSharedIdsAction {
    return {
        type: OrderActionTypes.SET_USER_SHARED_IDS,
        payload: {
            ordersId, sharingId, indicator
        }
    };
}
/********************************************************************************
 *  Set User Shared Login Email
 *******************************************************************************/
export interface SetUserSharedLoginAction {
    type: typeof OrderActionTypes.SET_USER_SHARED_LOGIN_EMAIL;
    payload: {
        sharedLoginEmail:string,
    };
}

export function SetSharedLoginEmail(sharedLoginEmail:string): SetUserSharedLoginAction {
    return {
        type: OrderActionTypes.SET_USER_SHARED_LOGIN_EMAIL,
        payload: {
            sharedLoginEmail
        }
    };
}
/********************************************************************************
 *  Set User Shared Login Phone-Number
 *******************************************************************************/
export interface SetUserSharedLoginPhoneNumberAction {
    type: typeof OrderActionTypes.SET_USER_SHARED_LOGIN_PHONE_NUMBER;
    payload: {
        sharedLoginPhoneNumber:string,
    };
}

export function SetSharedLoginPhoneNumber(sharedLoginPhoneNumber:string): SetUserSharedLoginPhoneNumberAction {
    return {
        type: OrderActionTypes.SET_USER_SHARED_LOGIN_PHONE_NUMBER,
        payload: {
            sharedLoginPhoneNumber
        }
    };
}
/********************************************************************************
 *  Set User Shared Accept Modal Details
 *******************************************************************************/
export interface SetUserSharedAcceptModalAction {
    type: typeof OrderActionTypes.SET_USER_SHARED_ACCEPT_MODAL;
    payload: {
        acceptModalDetails:any,
    };
}

export function SetSharedAcceptModal(acceptModalDetails:string): SetUserSharedAcceptModalAction {
    return {
        type: OrderActionTypes.SET_USER_SHARED_ACCEPT_MODAL,
        payload: {
            acceptModalDetails
        }
    };
}
/********************************************************************************
 *  Set User Navigation Route
 *******************************************************************************/
export interface SetUserNavigationRouteAction {
    type: typeof OrderActionTypes.SET_USER_NAVIGATION_ROUTE;
    payload: {
        naviagtionRoute:boolean,
    };
}

export function SetNavigationRoute(naviagtionRoute:boolean): SetUserNavigationRouteAction {
    return {
        type: OrderActionTypes.SET_USER_NAVIGATION_ROUTE,
        payload: {
            naviagtionRoute
        }
    };
}
/********************************************************************************
 *  Set User Confirm Accept Modal Tickets
 *******************************************************************************/
export interface SetUserConfirmAcceptModalAction {
    type: typeof OrderActionTypes.SET_USER_CONFIRM_ACCEPT_MODAL;
    payload: {
        confirmAcceptModal:boolean,
    };
}

export function SetConfirmAcceptModal(confirmAcceptModal:boolean): SetUserConfirmAcceptModalAction {
    return {
        type: OrderActionTypes.SET_USER_CONFIRM_ACCEPT_MODAL,
        payload: {
            confirmAcceptModal
        }
    };
}
/****************************************************************************************
  Set Orders Id For Batch Printing
****************************************************************************************/

export interface SetBatchPrintOrderIdsAction {
    type: typeof OrderActionTypes.SET_BATCH_PRINT_ORDER_IDS;
    payload: {
        orderIds: any[];
    };
}

export function setBatchPrintOrderIds(orderIds: any[]): SetBatchPrintOrderIdsAction {
    return {
        type: OrderActionTypes.SET_BATCH_PRINT_ORDER_IDS,
        payload: {
            orderIds
        }
    };
}

/********************************************************************************
 *  Cache Orders
 *******************************************************************************/

export interface CacheOrdersAction {
    type: typeof OrderActionTypes.CACHE_ORDERS;
    payload: {
        orders: IOrder[];
    };
}

export function cacheOrders(orders: IOrder[]): CacheOrdersAction {
    return {
        type: OrderActionTypes.CACHE_ORDERS,
        payload: {
            orders,
        },
    };
}

/********************************************************************************
 *  Cache Orders
 *******************************************************************************/

export interface setOrderRefundedAction {
    type: typeof OrderActionTypes.ORDER_REFUNDED;
    payload: {
        refunded: boolean;
    };
}

export function setOrderRefunded(refunded: boolean): setOrderRefundedAction {
    return {
        type: OrderActionTypes.ORDER_REFUNDED,
        payload: {
            refunded,
        },
    };
}