import { ApolloClient,from } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import errorLink from './links/errorLink';
import removeTypeNameLink from './links/removeTypeNameLink';
import requestLink from './links/requestLink';
import uploadLink from './links/uploadLink';
import debounceLink from '@sellout/models/.dist/graphql/links/debounceLink';

const cache = new InMemoryCache();

const link = from([
  requestLink,
  errorLink,
  removeTypeNameLink,
  debounceLink as any,
  uploadLink,
]);

export default new ApolloClient({ link, cache }) as any ;
