import React, { useEffect, useState } from "react";
import {
  EditorState,
  convertFromHTML as convertFromHTMLDraft,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Label, Colors } from "@sellout/ui";
import styled from "styled-components";
import { media } from "@sellout/ui/build/utils/MediaQuery";
import { convertToHTML } from "draft-convert";

type ContainerProps = {
  margin?: string;
};
const Container = styled.div<ContainerProps>`
  border: 1px solid ${Colors.Grey5};
  border-radius: 10px;
  background: ${Colors.White};
  margin: ${(props) => props.margin};
  min-height: 180px;
  ${media.mobile`
    max-width:350px;
  `};
`;

type RichTextEditorProps = {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  margin?: string;
  subLabel?: string;
  tip?: string;
  placeholder?: string;
};

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  onChange,
  label,
  margin,
  subLabel,
  tip,
  placeholder,
}) => {
  const convertFromHTML = (html) => {
    const blocksFromHTML = convertFromHTMLDraft(html);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return state;
  };

  const [editorState, setEditorState] = useState(() => {
    if (value === "") {
      return EditorState.createEmpty();
    }else{
      if (value) {
        const contentState = convertFromHTML(value);
        return EditorState.createWithContent(contentState);
      } 
      else {
        return EditorState.createEmpty();
      }
    }
  });

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const html = convertToHTML(contentState);
    onChange(html);
  };
  const isEditorEmpty = () => {
    const contentState = editorState.getCurrentContent();
    const isEmpty =
      contentState.getBlockMap().size === 1 &&
      contentState.getFirstBlock().getType() === "unstyled" &&
      contentState.getFirstBlock().getText() === "";
    return isEmpty;
  };
  useEffect(() => {
    if (value === "") {
      setEditorState(EditorState.createEmpty());
    }
  }, [value]);
  return (
    <>
      {label && <Label text={label} subText={subLabel} tip={tip} />}
      <Container margin={margin}>
        <Editor
          editorState={editorState}
          toolbar={{
            options: ["inline", "list"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
          onEditorStateChange={onEditorStateChange}
          placeholder={isEditorEmpty() ? placeholder : ""}
          spellCheck
        />
      </Container>
    </>
  );
};

export default RichTextEditor;
