import { useLazyQuery } from "@apollo/client";
import {
  Input,
  Validation,
  Icons,
  Label,
  UserInfo,
  Colors,
  PhoneNumberInput,
} from "@sellout/ui";
import { InputSizes } from "@sellout/ui/build/components/Input";
import { UserInfoSizeEnum } from "@sellout/ui/build/components/UserInfo";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BackstageState } from "../redux/store";
import { ModalContainer } from "./modal/Modal";
import { TabBody } from "./modal/RefundModal";
import * as OrderActions from "../redux/actions/order.actions";
import USER_EXISTS from "@sellout/models/.dist/graphql/queries/userExists.query";
import styled from "styled-components";
import ScreenHeaderMyTicket from "./ScreenHeaderMyTicket";
import { PhoneNumberInputSizes } from "@sellout/ui/build/components/PhoneNumberInput";
import { Border } from "./SelectTicketAddons";
import { ErrorKeyEnum } from "../redux/reducers/order.reducer";
import { ShareTicketSteps } from "./MyTicketShareScreenSteps";

const UserProfileContainer = styled.div`
  margin-top: 32px;
`;
const Text = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${Colors.Grey2};
  margin: 0px;
  text-align: left;
  line-height: 175%;
`;
export const EmailName = styled.span`
  font-weight: 600;
  font-size: 1.4rem;
  color: ${Colors.Grey1};
`;
const TabContainer = styled.div`
  padding: 20px 24px;
`;
const LabelText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.Grey1};
  /* margin-bottom: 1px; */
  font-weight: 600;
`;
export const Spacer = styled.div`
  width: 15px;
  padding: 14px;
`;
export const ContactInfo = ({ stepState, setStepState,setErrorMsg }) => {
  const dispatch = useDispatch();

  const orderState = useSelector((state: BackstageState) => state.order);
  const { email, errors, phoneNumber } = orderState;
  const inputErrors = errors as [ErrorKeyEnum];
  const initialState = {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    __typename: "User",
    _id: "",
  };
  const [customer, setCustomer] = React.useState(initialState);

  const [errorss, setErrorss] = useState<string>("");
  const [userNotfoundEmail, setUserNotfoundEmail] = useState<boolean>(false);
  const [userNotfoundPhNumber, setUserNotfoundPhNumber] =
    useState<boolean>(false);

  const setError = (key: ErrorKeyEnum, errorMsg: string) => {
    dispatch(OrderActions.SetErrors(key, errorMsg));
  };

  const setUserEmail = (email: string) =>
    dispatch(OrderActions.setSharingEmail(email));

  const setPhoneNumber = (phoneNumber: string) =>
    dispatch(OrderActions.SetPhoneNumber(phoneNumber));

  const setUserExists = (userExists: boolean) =>
    dispatch(OrderActions.SetUserExists(userExists));

  useEffect(() => {
    if(stepState ==ShareTicketSteps.ContactInfo){
      setErrorMsg("")
    }
    }, [])  

  useEffect(() => {
    if (email || phoneNumber) {
      getExistsEmail({
        variables: {
          email: email,
          phoneNumber: email && email?.length > 1 ? "" : phoneNumber,
        },
      });
    } else {
      setErrorss("");
    }
  }, [email, errors, phoneNumber]);
  const [getExistsEmail, { loading }] = useLazyQuery(USER_EXISTS, {
    fetchPolicy: "network-only",
    context: {
      debounceKey: "USER_EXISTS",
    },
    onCompleted: (data) => {
      const exists = Boolean(data?.userExists?.userId.length ?? 0 > 0);
      if (exists) {
        setCustomer({
          ...customer,
          email: data?.userExists?.email,
          firstName: data?.userExists?.firstName,
          lastName: data?.userExists?.lastName,
          phoneNumber: data?.userExists?.phoneNumber,
          __typename: "User",
          _id: data?.userExists?.userId,
        });
        setUserExists(true);
      } else {
        setCustomer(initialState);
        setUserExists(false);
      }
      data?.userExists?.userId?.length == 0 &&
      inputErrors[ErrorKeyEnum.UserEmail]?.length == 0
        ? setUserNotfoundEmail(true)
        : setUserNotfoundEmail(false);
      data?.userExists?.userId?.length == 0 &&
      inputErrors[ErrorKeyEnum.UserPhoneNumber]?.length == 0
        ? setUserNotfoundPhNumber(true)
        : setUserNotfoundPhNumber(false);
    },
  });

  return (
    <ModalContainer>
      <ScreenHeaderMyTicket
        title="Contact info"
        padding="18px 24px 14px"
        stepState={stepState}
        setStepState={setStepState}
      />
      <TabContainer>
        <TabBody>
          <LabelText>You can either enter email or phone number</LabelText>
          <Spacer />
          <Input
            autoFocus={true}
            type="email"
            label="Email address"
            placeholder="Enter your email address"
            size={InputSizes.Large}
            value={email as any}
            width="100%"
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const email = e.currentTarget.value;
              const message =
                Validation.email.validate(email)?.error?.message || "";
              setUserEmail(email);
              email
                ? setError(ErrorKeyEnum.UserEmail, message)
                : setError(ErrorKeyEnum.UserEmail, "");
            }}
            icon={Icons.EnvelopeLight}
            onClear={() => {
              setUserEmail("");
              setError(ErrorKeyEnum.UserEmail, "");
              setCustomer(initialState);
            }}
            disabled={
              phoneNumber && phoneNumber?.length > 1 ? true : (false as any)
            }
            margin="0px 0px 20px 0px"
            validationError={
              inputErrors ? inputErrors[ErrorKeyEnum.UserEmail] : ""
            }
          />
          <Border />
          <PhoneNumberInput
            autoFocus={false}
            label="Customer phone number"
            phoneNumberInputSize={PhoneNumberInputSizes.Large}
            value={phoneNumber as string}
            onChange={(phoneNumber: string) => {
              const message =
                Validation.phoneNumber.validate(phoneNumber)?.error?.message ||
                "";
              setPhoneNumber(phoneNumber);
              phoneNumber
                ? setError(ErrorKeyEnum.UserPhoneNumber, message)
                : setError(ErrorKeyEnum.UserPhoneNumber, "");
            }}
            disabled={email?.length ? true : false}
            validationError={
              phoneNumber && phoneNumber?.length > 1
                ? inputErrors[ErrorKeyEnum.UserPhoneNumber]
                : ""
            }
          />

          {(customer._id.length > 0 &&
            inputErrors[ErrorKeyEnum.UserEmail]?.length == 0) ||
          (customer.email && customer.email.length > 0) ||
          (customer.phoneNumber && customer.phoneNumber.length > 0) ? (
            <UserProfileContainer>
              <Label text="Is this the person?" />
              <UserInfo user={customer} size={UserInfoSizeEnum.Large} />
            </UserProfileContainer>
          ) : (
            ""
          )}

          {email &&
          email.length &&
          inputErrors[ErrorKeyEnum.UserEmail].length == 0 ? (
            userNotfoundEmail ? (
              <UserProfileContainer>
                <Label text="Existing customer not found" />
                <Text>
                  If you are sure that you
                  have the contact information correct, please click next.
                </Text>
              </UserProfileContainer>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {phoneNumber &&
          phoneNumber.length &&
          inputErrors[ErrorKeyEnum.UserPhoneNumber].length == 0 ? (
            userNotfoundPhNumber ? (
              <UserProfileContainer>
                <Label text="Existing customer not found" />
                <Text>
                  If you are sure that you have the contact
                  information correct, please click next.
                </Text>
              </UserProfileContainer>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </TabBody>
      </TabContainer>
    </ModalContainer>
  );
};
