import React, { useState } from "react";
import { Button, Colors } from "@sellout/ui";
import { ButtonTypes, ButtonStates } from "@sellout/ui/build/components/Button";
import { ButtonContainer } from "../../pages/EventOrders.page";
import { Container } from "./AddTicketBlockModal";
import * as AppActions from "../../redux/actions/app.actions";
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "./Modal";
import { useDispatch } from "react-redux";
import MyTicketShareScreenSteps from "../MyTicketShareScreenSteps";
import styled from "styled-components";

enum ConfirmModal {
  title = "Share your tickets",
  confirmText = "CANCEL",
  cancelText = "CONFIRM",
}
const ShareTicketConfirmationModal = () => {
  /* Actions */
  const dispatch = useDispatch();
  const [onClickConfirm, setOnClickConfirm] = useState<boolean>(false);
  const popModal = () => dispatch(AppActions.popModal());

  const ConfirmHeaderText = styled.span`
    font-weight: 600;
    font-size: 1.6rem;
    color: ${Colors.Grey1};
    display: flex;
    justify-content: center;
    padding: 0px 11px 12px 10px;
  `;
  const ConfirmText = styled.span`
    font-weight: 450;
    font-size: 1.4rem;
    color: ${Colors.Grey1};
        line-height: 23px;
  `;
  const confirmModal = () => {
    setOnClickConfirm(true);
  };
  return (
    <>
      {!onClickConfirm && (
        <ModalContainer width="430px">
          <>
            <ModalHeader title={ConfirmModal.title} close={popModal} />
            <ModalContent>
              <Container>
                {<ConfirmHeaderText>Are you sure?</ConfirmHeaderText>}{" "}
                <ConfirmText>
                  You can share your tickets with a friend by providing their
                  email address or phone number. They will receive a message
                  letting them know that you’ve shared tickets with them and
                  asking them to accept the tickets. Once they accept the
                  tickets, those tickets will be removed from your order. You
                  can always cancel the ticket share before they accept the
                  request. Are you sure you want to share your tickets?
                </ConfirmText>{" "}
              </Container>
            </ModalContent>
            <ModalFooter>
              <div />
              <ButtonContainer>
                <Button
                  type={ButtonTypes.Thin}
                  text={ConfirmModal.confirmText}
                  state={ButtonStates.Warning}
                  margin="0px 10px 0px 0px"
                  onClick={() => popModal()}
                />
                <Button
                  type={ButtonTypes.Thin}
                  text={ConfirmModal.cancelText}
                  state={ButtonStates.Active}
                  margin="0px 10px 0px 0px"
                  onClick={() => confirmModal()}
                />
              </ButtonContainer>
            </ModalFooter>
          </>
        </ModalContainer>
      )}
      {onClickConfirm && <MyTicketShareScreenSteps popModal={popModal} />}
    </>
  );
};

export default ShareTicketConfirmationModal;
